import { Firmware, FirmwareQueryParam, FirmwareUploadParam } from '/@/types/device-firmware';
import request, { CommonListResponse, IListWorkspaceResponse, IPage, IWorkspaceResponse } from '/@/api/http/request';
import { Device } from '/@/types/device';

const HTTP_PREFIX = '/manage/api/v1';

const RUOYI_PREFIX = '/OnWCtDVKE4H6';

// login
export interface LoginBody {
	username: string;
	password: string;
	flag?: number;
	tenantId?: string;
}
export interface BindBody {
	device_sn: string;
	user_id: string;
	workspace_id: string;
	domain?: string;
}
export interface HmsQueryBody {
	sns: string[];
	children_sn: string;
	device_sn: string;
	language: string;
	level: number | string;
	begin_time: number;
	end_time: number;
	message: string;
	domain: number;
}

export interface AddPostDataBody {
	roleName: string;
	roleKey: string;
	roleSort: number;
	status: string;
	menuCheckStrictly: boolean;
	deptCheckStrictly: boolean;
	remark?: string;
	menuIds: number[];
	deptIds: number[];
	createBy: string;
}

export interface AddJobDataBody {
	parentId: number;
	deptName: string;
	leader: string;
	status: string;
	info: string;
	createBy: string;
}
export interface userModal {
	username: string;
	workspaceId?: string;
	password: string;
	respassword?: string;
	deptId: number | string;
	roleIds: any[];
	userType: string;
	phonenumber: string;
	email: string;
	status: number;
	delFlag: string;
}

export async function getTanantById(tenant_id: string) {
	let tenantid;
	if (!tenant_id) {
		tenantid = localStorage.getItem('tenantId');
	} else {
		tenantid = tenant_id;
	}
	if (tenantid) {
		let tan = await request.get(`/admin-api/system/tenant/get?id=${tenantid}`);
		return tan;
	} else {
		return { code: -1 };
	}
}

interface MqttLoginBody {
	clientId: string;
	password: string;
	username: string;
}

export const mqttLogin = async (body: MqttLoginBody) => {
	let url = `/admin-api/system/auth/mqtt/login`;
	const result = await request.post(url, body);
	return result.data;
};

export const getIdByName = async (name = '水利管理平台') => {
	let url = `/admin-api/system/tenant/get-id-by-name?name=${name}`;
	const result = await request.get(url);
	return result.data;
};

interface SpeakerFileUrlData {
	url:string;
	id:string;
	name: string;
	type: 1 | 2
	tts_text: string
	object_key:string
	format: string
	md5: string
}
// url: "https://shanghai-jsredfly-test.oss-cn-shanghai.aliyuncs.com/speaker/203/e3dea0f5-37f2-4d79-ae58-490af3228069/1770264655089713152?Expires=1731033323&OSSAccessKeyId=LTAI5tLbW46gVoJfeR2mZ783&Signature=a5pb%2BAMQM84HJrkyRjvrv7KlB3M%3D"


let userWorkSpaceId = localStorage.getItem("workspace_id")


export const getQuerySpeakerFileUrl = async (play_file_md5:string): Promise<IWorkspaceResponse<SpeakerFileUrlData>>=>{
	let url = `${HTTP_PREFIX}/psdk/${userWorkSpaceId}/querySpeakerFile`
	const result = await request.get(url, { params: { play_file_md5 } });
	return result.data;
}

export const deleteJobData = async (id: string | number) => {
	let url = '/system/dept/';
	url += `${id}`;
	const result = await request.delete(url);
	return result.data;
};

export const deleteUserData = async (id: string | number, currentId: string | number | null) => {
	let url = '/manage/api/v1/users/remove?';
	url += `userIds=${id}&currentId=${currentId}`;
	const result = await request.delete(url);
	return result.data;
};

interface WaylineBody {
	name: string;
	drone: string;
	payload: string;
	template: string;
	username: any;
	workSpaceId: any;
}

// 获取当前租户下所有用户
export const getTenantUserList = async (workspace_id: string) => {
	const url = `/manage/api/v1/users/${workspace_id}/allUsers`;
	const result = await request.get(url);
	return result.data;
};
export const getWaylineById = async (workSpaceId: string, waypointId: string) => {
	let url = `/wayline/api/v1/workspaces/${workSpaceId}/waylines/get/${waypointId}`;
	const result = await request.get(url);
	return result.data;
};

export const saveWaylineDetail = async (lineId: string, workSpaceId: any, body: any, tenantId: any) => {
	let url = `/wayline/api/v1/workspaces/${workSpaceId}/waylines/${lineId}/saveWaylineDetail/${tenantId}`;
	const result = await request.post(url, body);
	return result.data;
};

export const saveWayline = async (body: WaylineBody) => {
	let url = `/wayline/api/v1/workspaces/${body.workSpaceId}/waylines/saveWayline`;

	const result = await request.post(url, body);
	return result.data;
};

export const deletePostData = async (id: string | number) => {
	let url = '/system/role/';
	url += `${id}`;
	const result = await request.delete(url);
	return result.data;
};

export const addJob = async (body: AddJobDataBody) => {
	const url = '/system/dept/';
	const result = await request.post(url, body);
	return result.data;
};

export const updateJob = async (id: string, body: any) => {
	const url = '/system/dept';
	const result = await request.put(url, body);
	return result.data;
};

export const addPost = async (body: AddPostDataBody) => {
	const url = '/system/role';
	const result = await request.post(url, body);
	return result.data;
};

export const addUsers = async (body: userModal) => {
	const url = '/manage/api/v1/users';
	const result = await request.post(url, body);
	return result.data;
};

export const login2 = async function (body: LoginBody, header: any): Promise<IWorkspaceResponse<any>> {
	const url = `/admin-api/system/auth/login`;

	const result = await request.post(url, body, { headers: header });
	return result.data;
};
/**
 * 查询组织列表
 * */
export const allSimpleList = async () => {
	try {
		let res = await request.get(`/admin-api/system/dept/list-all-simple`, {});
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 根据当前组织id查询组织列表
 * */
export const allSimpleListById = async (id) => {
	try {
		let res = await request.get(`/admin-api/system/dept/list-all-simple-by-id`, { params: { id } });
		return res.data;
	} catch (error) {
		throw error;
	}
};

export const getUserInfoData = async function (userId: string): Promise<any> {
	const url = `${RUOYI_PREFIX}/admin-api/system/user/get?id=${userId}`;
	const result = await request.get(url);
	return result.data;
};

export const login = async function (body: LoginBody): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/login`;
	const result = await request.post(url, body);
	return result.data;
};

// Refresh Token
export const refreshToken = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/token/refresh`;
	const result = await request.post(url, body);
	return result.data;
};

// Get Platform Info
export const getPlatformInfo = async function (): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/current`;
	const result = await request.get(url);
	return result.data;
};

// Get User Info
export const getUserInfo = async function (): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/users/current`;
	const result = await request.get(url);
	return result.data;
};

// Get Device Topo
export const getDeviceTopo = async function (workspace_id: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices`;
	const result = await request.get(url);
	return result.data;
};
// Get Device Topo
export const getDeviceTopoFromDept = async function (workspace_id: string): Promise<IWorkspaceResponse<any>> {
	const deptId = JSON.parse(localStorage.getItem('user')!).deptId;
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices-dept?dept_id=${deptId}`;
	const result = await request.get(url);
	return result.data;
};

// Get Livestream Capacity
export const getLiveCapacity = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/capacity`;
	const result = await request.get(url, body);
	return result.data;
};

// 通过sn获取设备信息
export const getLiveCapacityBySn = async function (deviceSn: string, body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/capacityBySn?deviceSn=${deviceSn}`;
	const result = await request.get(url, body);
	return result.data;
};

// Start Livestream
export const startLivestream = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/streams/start`;
	const result = await request.post(url, body);
	return result.data;
};

export const getShengToken = async (agoraAPPID: string, agoraChannel: string) => {
	const url = `/system/base/getAgoraToken?agoraAPPID=${agoraAPPID}&agoraChannel=${agoraChannel}`;
	const result = await request.get(url);
	return result.data;
};

// Stop Livestream
export const stopLivestream = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/streams/stop`;
	const result = await request.post(url, body);
	return result.data;
};
// Update Quality
export const setLivestreamQuality = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/streams/update`;
	const result = await request.post(url, body);
	return result.data;
};

export const getAllJobInfo = async (deptName?: string) => {
	let url = '/system/dept/list?';
	if (deptName) {
		url += `&deptName=${deptName}`;
	}

	const result = await request.get(url);
	return result.data;
};

export const getUserInfoById = async (id: string) => {
	const url = `/manage/api/v1/users/${id}`;
	const result = await request.get(url);
	return result.data;
};

// 获取部门树结构
export const getAllJobTree = async () => {
	const url = '/system/dept/deptTree';
	const result = await request.get(url);
	return result.data;
};
// 获取职务数据
export const getAllPostInfo = async function (body?: IPage, roleName?: string, sortType?: string): Promise<CommonListResponse<any>> {
	let url = '/system/role/list?';

	if (body && body.page && body.page_size) {
		url += `&page=${body.page}&page_size=${body.page_size}`;
	}
	if (roleName) {
		url += `&roleName=${roleName}`;
	}
	if (sortType) {
		url += `&sortType=${sortType}`;
	}

	const result = await request.get(url);
	return result.data;
};

export const updateUserInfoData = async (workspace_id: string, user_id: string, body: any) => {
	const url = `/manage/api/v1/users/${workspace_id}/users/${user_id}`;
	const result = await request.put(url, body);
	return result.data;
};

export const getAllUsersInfo = async function (wid: string, body: IPage): Promise<CommonListResponse<any>> {
	const url = `${HTTP_PREFIX}/users/${wid}/users?&page=${body.page}&page_size=${body.page_size}`;
	const result = await request.get(url);
	return result.data;
};

export const getAllPostInfoData = async function (id: string) {
	const url = `/system/role/${id}`;
	const result = await request.get(url);
	return result.data;
};
export const updatePost = async function (body: any) {
	const url = '/system/role/';
	const result = await request.put(url, body);
	return result.data;
};

export const getAllJobInfoData = async (id: string) => {
	const url = `/system/dept/${id}`;
	const result = await request.get(url);
	return result.data;
};

export const updateUserInfo = async function (wid: string, user_id: string, body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/users/${wid}/users/${user_id}`;
	const result = await request.put(url, body);
	return result.data;
};

export const bindDevice = async function (body: BindBody): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${body.device_sn}/binding`;
	const result = await request.post(url, body);
	return result.data;
};

export const unbindDevice = async function (device_sn: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${device_sn}/unbinding`;
	const result = await request.delete(url);
	return result.data;
};

export const getDeviceBySn = async function (workspace_id: string, device_sn: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/${device_sn}`;
	const result = await request.get(url);
	return result.data;
};

export enum Domain {
	'sub-device' = 'SUB_DEVICE',
	'gateway' = 'GATEWAY',
	'payload' = 'PAYLOAD',
	'dock' = 'DOCK',
}

export const getDevicesByDomain = async (domain: Domain, workspace_id: string, childSn?: string) => {
	let url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/getDevicesByDomain?domain=${domain}`;
	if (childSn) {
		url += `&childSn=${childSn}`;
	}
	const result = await request.get(url);
	return result.data;
};

/**
 * 获取绑定设备信息
 * @param workspace_id
 * @param body
 * @param domain
 * @returns
 */
export const getBindingDevices = async function (workspace_id: string, body: IPage, domain: number): Promise<IListWorkspaceResponse<Device>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/bound?&page=${body.page}&page_size=${body.page_size}&domain=${domain}`;
	const result = await request.get(url);
	return result.data;
};
/**
 * 新增直播通道列表
 * @param { }
 * @returns
 */
export const RTMPLiveCreat = async (workspace_id: string, data: {}) => {
	try {
		let res = await request.post(`/live/${workspace_id}/share/create`, data);
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 获取直播通道列表
 * @param { }
 * @returns
 */
export const RTMPLivePage = async (workspace_id: string, data: {}) => {
	try {
		let res = await request.post(`/live/${workspace_id}/share/pages?current=${data.current}&size=${data.size}`+ (data.expired?`&expired=${data.expired}`:''), {
			params: data
		});
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 获取单兵设备列表
 * @param { }
 * @returns
 */
import axios from 'axios';
const cancelToken = axios.CancelToken;
let source: any = null;
export const singleSoldierDevice = async (workspace_id: string, data: {}) => {
	/* if(source){
    source()
  } */
	try {
		let res = await request.get(`/singleSoldierDevice/${workspace_id}/queryByPage`, {
			params: data,
			cancelToken: new axios.CancelToken(function executor(c) {
				source = c;
			}),
		});
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 修改单兵设备信息
 * @param { }
 * @returns
 */
export const singleSoldierDeviceEdit = async (workspace_id: string, data: {}) => {
	try {
		let res = await request.put(`/singleSoldierDevice/${workspace_id}/edit`, data);
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 删除单兵设备
 * @param { }
 * @returns
 */
export const singleSoldierDeviceDeleteById = async (workspace_id: string, data: {}) => {
	try {
		let res = await request.delete(`/singleSoldierDevice/${workspace_id}/deleteById`, { params: data });
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 播放单兵视频
 * @param { }
 * @returns
 */
export const singleSoldierDeviceStartStream = async (workspace_id: string, data: {}) => {
	try {
		let res = await request.post(`/singleSoldierDevice/${workspace_id}/startStream`, data);
		return res.data;
	} catch (error) {
		throw error;
	}
};

export const getShipById = async (id: string) => {
	try {
		let res = await request.get(`/gateway/ship/getById?id=${id}`);
		return res.data;
	} catch (error) {
		throw error;
	}
};

export const updateDevice = async function (body: {}, workspace_id: string, device_sn: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/${device_sn}`;
	const result = await request.put(url, body);
	return result.data;
};

export const getUnreadDeviceHms = async function (workspace_id: string, device_sn: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/hms/${device_sn}`;
	const result = await request.get(url);
	return result.data;
};

export const updateDeviceHms = async function (workspace_id: string, device_sn: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/devices/${workspace_id}/devices/hms/${device_sn}`;
	const result = await request.put(url);
	return result.data;
};

export const getDeviceHms = async function (body: HmsQueryBody, workspace_id: string, pagination: IPage): Promise<IListWorkspaceResponse<any>> {
	let url =
		`${HTTP_PREFIX}/devices/${workspace_id}/devices/hms?page=${pagination.page}&page_size=${pagination.page_size}` +
		`&level=${body.level ?? ''}&begin_time=${body.begin_time ?? ''}&end_time=${body.end_time ?? ''}&message=${body.message ?? ''}&language=${
			body.language
		}`;
	body.sns.forEach((sn: string) => {
		if (sn !== '') {
			url = url.concat(`&device_sn=${sn}`);
		}
	});
	const result = await request.get(url);
	return result.data;
};

export const changeLivestreamLens = async function (body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/live/streams/switch`;
	const result = await request.post(url, body);
	return result.data;
};

export const getFirmwares = async function (workspace_id: string, page: IPage, body: FirmwareQueryParam): Promise<IListWorkspaceResponse<Firmware>> {
	const url =
		`${HTTP_PREFIX}/workspaces/${workspace_id}/firmwares?page=${page.page}&page_size=${page.page_size}` +
		`&device_name=${body.device_name}&product_version=${body.product_version}&status=${body.firmware_status ?? ''}`;
	const result = await request.get(url);
	return result.data;
};

export const importFirmareFile = async function (workspaceId: string, param: FormData): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/firmwares/file/upload`;
	const result = await request.post(url, param);
	return result.data;
};

export const changeFirmareStatus = async function (
	workspaceId: string,
	firmwareId: string,
	param: { status: boolean }
): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/firmwares/${firmwareId}`;
	const result = await request.put(url, param);
	return result.data;
};

/**
 * 用户和设备绑定列表
 * https://app.apifox.com/link/project/3592442/apis/api-145716546
 * @param workspace_id /{{manage_version}}/users/{{workspace_id}}/userBindDevice
 * @param task_id
 */
export const userBindDevice = async (workspace_id: string, deptId: string) => {
	try {
		let res = await request.get(`manage/api/v1/users/${workspace_id}/userBindDevice`, { params: { deptId } });
		return res.data;
	} catch (error) {
		throw error;
	}
};

//获取psdk资源
export const getPsdkRsource = async function (workspace_id: string, sn: string, psdk_index: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/psdk/${workspace_id}/psdk/ui-resource?device_sn=${sn}&psdk_index=${psdk_index}`;
	const result = await request.get(url);
	return result.data;
};
export interface IpsdkWidgetValueSet {
	gateway_sn?: string;
	index?: number;
	psdk_index?: number;
	value?: string;
	[property: string]: any;
}

//psdk-设置控件值
export const psdkWidgetValueSet = async function (workspace_id: string, body: IpsdkWidgetValueSet): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/psdk/${workspace_id}/psdk/psdkWidgetValueSet`;
	const result = await request.post(url, body);
	return result.data;
};

//psdk-发送文本框内容
export const psdkInputBoxTextSet = async function (workspace_id: string, body: IpsdkWidgetValueSet): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/psdk/${workspace_id}/psdk/psdkInputBoxTextSet`;
	const result = await request.post(url, body);
	return result.data;
};
