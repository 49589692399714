

<template>
    <div id="waypoint_dialog" class="waypoint_dialog" ref="createCreative">
        <a-modal 
          v-model:visible="waypointDialogVisible"
          :getContainer="() => createCreative"
          @cancel="cancel"
          width="480px"
          style="background:#1c1c1c"
          title="创建新航线"
        > 
        <template #footer>
            <a-button key="back" @click="handleCancel">取消</a-button>
            <a-button key="submit" type="primary" :loading="loading"  @click="handleOk">添加航线</a-button>
        </template>
        <wayline-create @updateStatus="updateStatus"></wayline-create>
      </a-modal>
    </div>
</template>

<script lang="ts" setup>
import { ref, reactive, defineProps, watch,onMounted, computed } from "vue"
import createWaypointDrone from "/@/assets/image/createWaypointDrone.png"
import { MinusCircleOutlined } from '@ant-design/icons-vue'
import { getRoot,getApp } from "/@/root";
import WaylineCreate from "/@/components/wayline/create.vue";
import { ELocalStorageKey, ERouterName } from "/@/types";
import { Domain, getBindingDevices, getDevicesByDomain, getDeviceTopo, saveWayline,saveWaylineDetail } from "/@/api/manage";
import { message } from "ant-design-vue";
import { DEVICE_MODEL_KEY } from "/@/types/device";
import { useMyStore } from "/@/store";
import moment from "moment";
const store = useMyStore();
const root =  getRoot()

let yuntaiRef = ref(null)
let yuntaiDialogRef = ref(null)

let createCreative = ref(null)

let tenantid = localStorage.getItem("tenantId")

const emit = defineEmits(['clone', 'confirm'])
let props = defineProps<{
    visible?: boolean
}>()

interface CreateWayPointLineData{
    name:string
    selectdrone:string
    camera:string
    waypointType:string
    selectDock:string
}



const creataWaypointDataRef = ref(null)

watch(()=>props.visible,(val)=>{
    waypointDialogVisible.value = val || false
})

const loading = ref(false)
const waypointDialogVisible = ref(false)
const createWaypointLineData = reactive<CreateWayPointLineData>({
    name:"新建航线", // 航线名称
    selectdrone:"", // 飞机数据
    camera:"",
    waypointType:"0",
    selectDock:""
})

const updateStatus = (item:CreateWayPointLineData) =>{
  createWaypointLineData.name = item.name  
  createWaypointLineData.selectdrone = item.selectdrone  
  createWaypointLineData.camera = item.camera  
  createWaypointLineData.waypointType = item.waypointType  
  createWaypointLineData.selectDock = item.selectDock  
}
let cancel = (e: any)=>{
  waypointDialogVisible.value = false
  emit("clone",e)
}

const selectDrone = async (e)=>{
  console.info("selectDrone",e)
  let workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId) || ''
  if(!selectCameraData.value[e]){
    let getDevices = await getDevicesByDomain(
      Domain["sub-device"],
      workspaceId,
      e)
    console.info("getDevices",getDevices)
    if(getDevices.code == 0){
      // if(!selectCameraData.value[createWaypointLineData.selectdrone] ){
        let deviceData =  getDevices.data[0].payload.map(ele=>{
          return {
            label:ele.payload_name,
            value:ele.payload_sn,
          }
        })

        console.info("deviceData",deviceData)
        selectCameraData.value[createWaypointLineData.selectdrone] = deviceData
      // }
      createWaypointLineData.camera = deviceData[0].value

    }
  }
  
  console.info("selectCameraData",selectCameraData.value)


}

type DEVICE_MODEL_KEY_DATA = typeof DEVICE_MODEL_KEY

// type DroneType = keyof DEVICE_MODEL_KEY_DATA

// let droneArray:DroneType[] = ["M30","M30T","M3E","M3T","M300","M350"]

let droneList = [
  {
    "label":"M30系列",
    "value":"1"
  },{
    "label":"Mavic 3 行业系列",
    "value":"2"
  }
]
let cameraList:any = ref({
  "1":[{
    "label":"M30 相机",
    "value":"1"
  },{
    "label":"M30T 相机",
    "value":"2"
  }],
  "2":[{
    "label":"Mavic 3E 相机",
    "value":"3"
  },{
    "label":"Mavic 3T 相机",
    "value":"4"
  }]
})


let getCameraList = computed(()=>{
  console.info("createWaypointLineData.selectdrone",createWaypointLineData.selectdrone)
  let camers = cameraList.value[createWaypointLineData.selectdrone]
  createWaypointLineData.camera = camers[0].value
  
  return camers
})
/**
 * cameraList[createWaypointLineData.selectdrone]
 */

interface DockerList {
  value:string
  label:string
}
const dockerLiseSelect = ref<DockerList[]>()


const selectDockListData = async ()=>{

  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!

  let deviceList = await getDeviceTopo(workspaceId)


  console.info("deviceList",deviceList)
  
  if(deviceList.code == 0){
    let dockList = (deviceList.data as any).filter(((ele:any)=>{
      return  ele.domain == 3 && ele.status
    })).map((eles:any)=>{
      return {
        label:eles.nickname,
        value:eles.device_sn
      }
    })
    dockerLiseSelect.value = dockList
  }

}


onMounted(async ()=>{
    selectDroneData.value = droneList
    createWaypointLineData.selectdrone = droneList[0].value
    // selectCameraData.value = cameraList[createWaypointLineData.selectdrone]
    createWaypointLineData.camera = cameraList.value[createWaypointLineData.selectdrone][0].value
  // }
    selectDockListData()


})

let droneOrPayload:any = {
  "1":{
    drone:DEVICE_MODEL_KEY["M30"],
    camera:DEVICE_MODEL_KEY["M30Camera"]
  },
  "2":{
    drone:DEVICE_MODEL_KEY["M30T"],
    camera:DEVICE_MODEL_KEY["M30TCamera"]
  },
  "3":{
    drone:DEVICE_MODEL_KEY["M3E"],
    camera:DEVICE_MODEL_KEY["M3ECamera"]
  },
  "4":{
    drone:DEVICE_MODEL_KEY["M3T"],
    camera:DEVICE_MODEL_KEY["M3TCamera"]
  },
  "5":{
    drone:DEVICE_MODEL_KEY["M3D"],
    camera:DEVICE_MODEL_KEY["M3DCamera"]
  },
  "6":{
    drone:DEVICE_MODEL_KEY["M3TD"],
    camera:DEVICE_MODEL_KEY["M3TDCamera"]
  }
}

let handleCancel = ()=>{
  waypointDialogVisible.value = false
  emit("clone",false)
}
interface UserInfo {
  access_token?: string;
  mqtt_addr?: string;
  mqtt_password?: string;
  mqtt_username?: string;
  user_id?: string;
  user_type?: number;
  username?: string;
  workspace_id?: string;
}

let docker = computed(()=>store.state.positionData.dock)

// 修改当前的数据
const handleOk = async ()=>{
  const username = localStorage.getItem(ELocalStorageKey.Username) 
  const workspace_id = localStorage.getItem(ELocalStorageKey.WorkspaceId)

  let data:any = {
    name:createWaypointLineData.name,
    template:createWaypointLineData.waypointType,
    username:username,
    workSpaceId: workspace_id
  }
  data.drone = droneOrPayload[createWaypointLineData.camera].drone
  data.payload = droneOrPayload[createWaypointLineData.camera].camera
  data.dockSn = createWaypointLineData.selectDock
  
  // // 发送请求
  // if(createWaypointLineData.selectDock == ''){
  //   return message.error("机场sn不能为空")
  // }
  let wayline = await saveWayline(data)
  if(wayline.code == 0){
    
    let userInfoString = localStorage.getItem("user");
    let userInfo: UserInfo = {};
    if (
      userInfoString &&
      Object.prototype.toString.call(userInfoString) == "[object String]"
    ) {
      userInfo = JSON.parse(userInfoString);
    }
    
    let waylineDataByKey = {
      template: {
        Document: {
          createTime: moment().valueOf(),
          author: userInfo.username,
          missionConfig: {
            finishAction: "goHome",
            takeOffSecurityHeight: 20,
            globalTransitionalSpeed: 20,
            globalRTHHeight: "100",
            exitOnRCLost: "executeLostAction",
            executeRCLostAction: "goBack",
            droneInfo: {
              droneSubEnumValue: "1", // 飞机挂载
              droneEnumValue: "67",
            },
            flyToWaylineMode: "safely",
            payloadInfo: {
              payloadEnumValue: "53",
              payloadPositionIndex: "0",
              payloadSubEnumValue: "0",
            },
            // takeOffRefPoint: [takeOffRefPoint.lon, takeOffRefPoint.lat], // 起飞点
            takeOffRefPointAGLHeight: 0,
          },
          updateTime:moment().valueOf(),
          Folder:{
            duration:0,
            distance:0,
            waylineCoordinateSysParam: {
              coordinateMode: "WGS84",
              heightMode: "relativeToStartPoint",
            },
            payloadParam: {
              imageFormat: "wide,zoom,ir",
              meteringMode: "average",
              returnMode: "singleReturnFirst",
              focusMode: "firstPoint",
              samplingRate: "240000",
              payloadPositionIndex: "0",
              scanningMode: "repetitive",
            },
            globalWaypointHeadingParam: {
              waypointPoiPoint: "0.000000,0.000000,0.000000",
              waypointHeadingPoiIndex: "0",
              waypointHeadingPathMode: "followBadArc",
              waypointHeadingAngle: "0",
              waypointHeadingMode: "followWayline",
            },

            templateType: "waypoint", //预定义模板类型
            globalWaypointTurnMode: "coordinateTurn",
            globalUseStraightLine: "1",
            globalHeight: 50,
            caliFlightEnable: "0",
            templateId: "0",
            autoFlightSpeed: 20,
            gimbalPitchMode: "manual",
            Placemark: [],
          },
        },
      }
    }

    await saveWaylineDetail(wayline.data,workspace_id,waylineDataByKey,tenantid)
    
    let getWaypointCenter = localStorage.getItem("WaypointCenter")
    if(!getWaypointCenter){
      localStorage.setItem("WaypointCenter",JSON.stringify(docker.value))
    }
    
    root.$router.replace({
      name:ERouterName.CREATEWAYPOINTDATA,
      query:{
        id:wayline.data,
        baseUrl:root.$route.fullPath
      }
    }).then(()=>{
      location.reload()
    });

    message.success("航线创建成功")
  }else{
    return message.error(wayline.message)
  }
}

let yuntaiSelectStatus = ref(false)
const yuntaiClick = ()=>{
  // 打开下拉框
  yuntaiSelectStatus.value = true
  // 选择的飞机
  let droneId = createWaypointLineData.selectdrone

}

// window.onclick = function(){

// }

// 成功回调
const onFinish = ()=>{

}
// 失败回调
const onFinishFailed = () => {

}

interface Drone {
  label:string
  value:string
}

const selectDroneData = ref<Drone[]>()
const selectWaypointTypeData = [{
  label:"航点航线",
  image:"http://",
  icon:"iconfont icon-a-dingwei1",
  value:"0"
},{
  label:"面状航线",
  image:"http://",
  disabled:true,
  icon:"iconfont icon-hangxian",
  value:"1"
},{
  label:"带状航线",
  image:"http://",
  disabled:true,
  icon:"iconfont icon-gonglu",
  value:"2"
}]

interface selectData{
  label:string
  value:string
  id?:string
}

interface SelectCameraData{
  [key:string]:selectData[]
}

const selectCameraData = ref<SelectCameraData>({
})


</script>

<style lang="scss" scoped >
::v-deep .ant-modal-close-x{
  line-height:38.67px !important;
  width: 38.67px !important;
  height: 38.67px !important;
  color: #fff;
}

::v-deep  .ant-modal-content{
  // color: hsla(0,0%,100%,.45);
   background: #1c1c1c !important;
   color: #fff !important;
   // text-align: center !important;
  }
  ::v-deep  .ant-modal-header{
   background: #1c1c1c !important;
   padding: 8px !important;
   line-height: 24px !important;
   
  }
  ::v-deep .ant-modal-body{
    padding:32px 32px 0 32px !important;
  }
 
  ::v-deep .ant-modal-title {
      background: #1c1c1c !important;
      color: #fff !important;
      text-align: center !important;
  }
  ::v-deep .ant-form-item{
    margin-bottom:16px
  }
  ::v-deep .ant-form-item-label  {
    label{
      color: #fff !important;
      height: auto;
    }
    
  }
  ::v-deep .ant-select-selector{
    border-radius: 2px;
    padding: 4px;
    cursor: pointer;
    color: #fff;
    background-color: rgba(0,0,0,.5) !important;

  }

:deep(.ant-modal-footer){
  border-top: none;
}

.waypointType{
  width: 100px;
  height: 100px !important;
  display: flex;
  display: inline-flex;
  flex-direction: column;justify-content: center;
  align-items: center;
 background-color: #1c1c1c !important;
  
}
// .waypoint_name{
//   .ant-select-selector{
//     background: rgb(60, 60, 60) !important;
//     color: rgb(255, 255, 255);
//   }
// }

.waypointType_content{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

 
  .flex_row_center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  
   
   .yuntai{
    margin-bottom: 0px !important;
    background-color: rgba(0,0,0,.5) !important;
    // background: rgba(0,0,0,.5);
    border-radius: 2px;
    padding: 4px;
    cursor: pointer;
    
  }

  .gimbal-0 {
    right: 50%;
    transform: translateX(50%);
    bottom: 4px;
  }
  .component-payload-card {
    position: absolute;
    width: 150px;
  }
  .component-payload-card {
    background: rgba(0,0,0,.5);
    border: 1px solid hsla(0,0%,100%,.2);
    border-radius: 2px;
    padding: 4px;
    cursor: pointer;
  }
  .component-payload-card .payload-card-title {
    font-size: 12px;
    line-height: 20px;
    color: #fff;

  }
  .flex-container-center, .flex-container-column-center {
    justify-content: center;
  }
  .flex-container-align-center, .flex-container-center, .flex-container-column-center {
    align-items: center;
  }
  .flex-container, .flex-container-align-center, .flex-container-center, .flex-container-column-center, .flex-container-direction-column, .flex-container-justify-between {
    display: flex;
  }
  
  .component-payload-card .payload-point {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
  }
  
  .component-payload-card .payload-card-add-text {
    font-size: 12px;
    line-height: 20px;
    color: #fff;
  }

.payload-card-overlay {
    background: #000;
    border-radius: 2px;
    border: 1px solid hsla(0,0%,100%,.2);
}
.wayline-app-ant-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #595959;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: normal;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1049;
    display: block;
}
.payload-card-overlay .content-list {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
}
.payload-card-overlay .content-item.selected {
    background: #232323;
    color: #2b85e4;
}
.payload-card-overlay .content-item {
    min-height: 32px;
    padding: 5px 12px;
    cursor: pointer;
}
.payload-card-overlay .content-item {
    min-height: 32px;
    padding: 5px 12px;
    cursor: pointer;
}

.waypoint_drone_select{
  ::v-deep .ant-select-dropdown-menu-item:hover {
        
        // background: linear-gradient(90deg, rgba(27, 139, 243, 0.3) -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;
        
  background: #3c3c3c !important;
  }
  ::v-deep  .ant-select-dropdown-menu-item-selected {
        color: #fff !important;
        // background: linear-gradient(90deg, #1B8BF3 -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;
        
  background: #3c3c3c !important;
 }
 ::v-deep  .ant-select-dropdown-menu-item-active {
        // background: linear-gradient(90deg, #1B8BF3 -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;
        
  background: #3c3c3c !important;
 }      

 ::v-deep  .ant-select-selector{
    border: 1px solid transparent !important;
    background: #3c3c3c !important;
    color: #fff !important;
  }
  color: #fff !important;
}

// .waypoint_drone_select_op:hover{
//   background: red !important;
// }
// .waypoint_drone_select_op



  // &:deep(.ant-modal-content){
  //   background-color:#1c1c1c !important;
  // }
  
  
  // &:deep(.ant-modal-header){
  //     background: #1c1c1c !important;
  // }
  
  // &:deep(.ant-modal-title){
  //     background: #1c1c1c !important;
  //     color: #fff !important;
  //     text-align: center !important;
  // }



</style>


<style>
/* .waypoint_drone_select_op{
  background: #3c3c3c !important;
}

.waypoint_drone_select_op:hover{

  background-color: #3c3c3c !important;
} */

/* .waypoint_drone_select_option:hover{
} */
</style>