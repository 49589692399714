import { message } from 'ant-design-vue';
import request, { IPage, IWorkspaceResponse, IListWorkspaceResponse } from '/@/api/http/request';
import requestData from '/@/api/http/requestother';
import { TaskType, TaskStatus, OutOfControlAction } from '/@/types/task';
import { WaylineType } from '/@/types/wayline';
import { ComputedRef } from 'vue';
import { Moment } from 'moment';

import { ELocalStorageKey } from '/@/types/enums';
const HTTP_PREFIX = '/wayline/api/v1';
let workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!;

// 获取单条航线信息
export const getRouteInfo = async function (wid: string = workspaceId , waylineId: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${wid}/waylines/${waylineId}/getOne`;
	const result = await request.get(url);
	return result.data;
};

// Get Wayline Files
export const getWaylineFiles = async function (wid: string = workspaceId, body: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${wid}/waylines/pc`;
	const result = await request.get(url,{params:body});
	return result.data;
};

// Download Wayline File
export const downloadWaylineFile = async function (workspaceId: string, waylineId: string): Promise<any> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/waylines/${waylineId}/url`;
	const result = await request.get(url, { responseType: 'blob' });
	console.info("result",result)
	if (result.data.type === 'application/json') {
		const reader = new FileReader();
		reader.onload = function (e) {
			const text = reader.result as string;
			const result = JSON.parse(text);
			// message.error(result.message)
		};
		reader.readAsText(result.data, 'utf-8');
	} else {
		return result.data;
	}
};



export const getFile = (url:string) => {
	return requestData.get(url, { responseType: 'blob' })
	// return request.get(url, { responseType: 'blob' })
}


export const downloadFileData = async function(workspaceId: string, waylineId: string) {
	// request.get('http://32.121.75.14:9000/cloud-bucket/203/wayline/%E6%B5%8B%E8%AF%95%E8%88%AA%E7%BA%BF_202405300959.kmz?response-content-type=application%2Fzip&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=miniouser%2F20240530%2Fcn-hangzhou%2Fs3%2Faws4_request&X-Amz-Date=20240530T033935Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=ae50f33c85e61884d98bfe05832c5fcfb060b6b006d7736a5205e6d004a00440')

	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/waylines/${waylineId}/urlPath`; 
	const result = await request.get(url)
	return result.data



}


// Delete Wayline File
export const deleteWaylineFile = async function (workspaceId: string, waylineId: string): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/waylines/${waylineId}`;
	const result = await request.delete(url);
	return result.data;
};

export interface AiVideo {
	aiRecognition: boolean;
	recognitionType: string;
	video_quality: string;
	execute_time?: number; // 执行时间（毫秒）
}

export interface CreatePlan extends AiVideo {
	name: string;
	file_id: string;
	dock_sn: string;
	task_type: TaskType; // 任务类型
	wayline_type: WaylineType; // 航线类型
	task_days?: number[]; // 执行任务的日期（秒）
	task_periods?: number[][]; // 执行任务的时间点（秒）
	rth_altitude: number; // 相对机场返航高度 20 - 500
	out_of_control_action: OutOfControlAction; // 失控动作
	min_battery_capacity?: number; // The minimum battery capacity of aircraft.
	min_storage_capacity?: number; // The minimum storage capacity of dock and aircraft.
}

export interface RepeatTimed {
	repeatMode: number;
	cron: string;
}

interface PlanBody {
	name: string;
	file_id: string | ComputedRef<string>;
	dock_sn: string | ComputedRef<string>;
	task_type: number | string;
	select_execute_time?: Moment | undefined;
	out_of_control_action: string | number;
}

interface CreatePlanBodyData {
	aiRecognition: boolean;
	recognitionType: number;
	cameraMode: 'ir' | 'normal';
	sharpness: string;
}

interface CreatePlanInfo {
	task_days: string;
	task_periods: string;
	rth_altitude?: number;
	wayline_type: string | any;
}
export type CreatePlanBody = PlanBody & CreatePlanBodyData & CreatePlanInfo & RepeatTimed;

// Create Wayline Job
export const createPlan = async function (workspaceId: string, plan: CreatePlanBody): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/flight-tasks`;
	const result = await request.post(url, plan);
	return result.data;
};

export interface Task {
	job_id: string;
	job_name: string;
	task_type: TaskType; // 任务类型
	file_id: string; // 航线文件id
	file_name: string; // 航线名称
	wayline_type: WaylineType; // 航线类型
	dock_sn: string;
	dock_name: string;
	workspace_id: string;
	username: string;
	begin_time: string;
	end_time: string;
	execute_time: string;
	completed_time: string;
	status: TaskStatus; // 任务状态
	progress: number; // 执行进度
	code: number; // 错误码
	rth_altitude: number; // 相对机场返航高度 20 - 500
	out_of_control_action: OutOfControlAction; // 失控动作
	media_count: number; // 媒体数量
	uploading: boolean; // 是否正在上传媒体
	uploaded_count: number; // 已上传媒体数量
}

// Get Wayline Jobs
export const getWaylineJobs = async function (workspaceId: string, page: IPage): Promise<IListWorkspaceResponse<Task>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/jobs?page=${page.page}&page_size=${page.page_size}`;
	const result = await request.get(url);
	return result.data;
};

export interface DeleteTaskParams {
	job_id: string;
}

//  删除机场任务
export async function deleteTask(workspaceId: string, params: DeleteTaskParams): Promise<IWorkspaceResponse<{}>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/jobs`;
	const result = await request.delete(url, {
		params: params,
	});
	return result.data;
}

export enum UpdateTaskStatus {
	Suspend = 0, // 暂停
	Resume = 1, // 恢复
}
export interface UpdateTaskStatusBody {
	job_id: string;
	status: UpdateTaskStatus | number;
}

// 更新机场任务状态
export async function updateTaskStatus(workspaceId: string, body: UpdateTaskStatusBody): Promise<IWorkspaceResponse<{}>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/jobs/${body.job_id}`;
	const result = await request.put(url, {
		status: body.status,
	});
	return result.data;
}
// 启用定时任务任务状态
export async function startRepeatJob(workspaceId: string, job_id: string): Promise<IWorkspaceResponse<{}>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/startRepeatJob/${job_id}`;
	const result = await request.put(url, {});
	return result.data;
}
// 停用定时任务任务状态
export async function stopRepeatJob(workspaceId: string, job_id: string): Promise<IWorkspaceResponse<{}>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/stopRepeatJob/${job_id}`;
	const result = await request.put(url, {});
	return result.data;
}

interface JobByJobId {
	ai_recognition: boolean;
	begin_time: string;
	dock_name: string;
	dock_sn: string;
	end_time: string;
	file_id: string;
	file_name: string;
	job_id: string;
	job_name: string;
	media_count: number;
	out_of_control_action: number;
	repeat_status: number;
	rth_altitude: number;
	status: number;
	task_type: number;
	username: string;
	wayline_type: number;
	workspace_id: string;
	[property: string]: any;
}
//是否开启了AI
export async function getJobByJobId(workspaceId: string, job_id: string): Promise<IWorkspaceResponse<JobByJobId>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/getJobByJobId/${job_id}`;
	const result = await request.get(url, {});
	return result.data;
}
// Upload Wayline file
export const importKmzFile = async function (workspaceId: string, file: {}): Promise<IWorkspaceResponse<any>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/waylines/file/upload`;
	const result = await request.post(url, file, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return result.data;
};

// 媒体立即上传
export const uploadMediaFileNow = async function (workspaceId: string, jobId: string): Promise<IWorkspaceResponse<{}>> {
	const url = `${HTTP_PREFIX}/workspaces/${workspaceId}/jobs/${jobId}/media-highest`;
	const result = await request.post(url);
	return result.data;
};

// 获取航线
export const getTrack = async function (sn: string, taskId: string, startTime: string, endTime: string): Promise<IWorkspaceResponse<{}>> {
	const url = `/aiIdentify/getOSDBySN?sn=${sn}&taskId=${taskId}&startTime=${startTime}&endTime=${endTime}`;
	const result = await request.get(url);
	return result.data;
};

// 获取航线
export const getWaylineByJobId = async function (job_id: string): Promise<IWorkspaceResponse<{ lon: string; lat: string }[]>> {
	const workspace_id = localStorage.getItem('workspace_id');
	const url = `/wayline/api/v1/workspaces/${workspace_id}/waylines/get/jobId/${job_id}`;
	const result = await request.get(url);
	return result.data;
};

//获取航线视频
export const getMediaByData = async function (taskId: string): Promise<IWorkspaceResponse<{}>> {
	const workspace_id = localStorage.getItem('workspace_id');
	const url = `/wayline/api/v1/workspaces/${workspace_id}/waylines/get/Media/${taskId}`;
	const result = await request.get(url);
	if (result.data.code == 0 && result.data.data && result.data.data.filePath) {
		return result.data;
	} else {
		let getMediaAiData = await getMediaAi(taskId);
		console.info('getMediaAiData', getMediaAiData);
		return getMediaAiData;
	}
};

/**
 * @param taskId 任务id
 * @returns
 */
export const getMediaAi = async function (taskId: string): Promise<IWorkspaceResponse<{}>> {
	const workspace_id = localStorage.getItem('workspace_id');
	const url = `/wayline/api/v1/workspaces/${workspace_id}/waylines/get/mediaAi/${taskId}`;
	const result = await request.get(url);
	if (result.data.code == 0 && result.data.data && result.data.data.filePath) {
		return result.data;
	}

	return {
		code: 0,
		message: '',
		data: {
			filePath: '',
		},
	};
};

/**
 * @param taskId 任务id
 * @returns
 */
export const getMedia = async function (taskId: string): Promise<IWorkspaceResponse<{}>> {
	const workspace_id = localStorage.getItem('workspace_id');
	const url = `/wayline/api/v1/workspaces/${workspace_id}/waylines/get/mediaVideo/${taskId}`;
	const result = await request.get(url);
	if (result.data.code == 0 && result.data.data && result.data.data.filePath) {
		return result.data;
	} else {
		let getMediaAiData = await getMediaAi(taskId);
		console.info('getMediaAiData', getMediaAiData);
		return getMediaAiData;
	}
};
